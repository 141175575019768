import { Controller } from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {
  connect() {
    const header = document.querySelector("header");
    const headerSerie = document.querySelector(".header-serie");
    const headerEpisodes = document.querySelector(".header-episodes");
    const menu = document.querySelector(".menu");
    const burger = document.querySelector(".burger");
    const menuMobile = document.querySelector(".menu-mobile-right");
    const burgerMobile = menuMobile.querySelector(".burger");
    const overlay = document.querySelector(".overlay");
    const audioSearch = document.querySelector(".audio-search");

    var siteName = "slate";
    if(document.body.classList.contains("korii")) {
      siteName = "korii";
    } else if(document.body.classList.contains("audio")) {
      siteName = "audio";
    }
    
    var lastY = 0;
    addEventListener("scroll", () => {
      var delta = scrollY - lastY;
      if(scrollY > 0) {
        document.body.classList.toggle("minified", delta > 0);
      } else {
        document.body.classList.remove("minified");
        if(headerSerie) headerSerie.classList.remove("opened");
      }
      lastY = scrollY;
    });

    burger.addEventListener("click", () => {
      var siteButton = menu.querySelector(`li.${siteName}`), active = menu.querySelector("li.active");
      overlay.classList.toggle("active");
      document.body.classList.toggle("overlayed");
      burger.classList.toggle("menu-opened");
      menu.classList.toggle("menu-opened");
      if(active && !active.classList.contains(siteName)) {
        active.classList.toggle("active");
      } else if(!active) {
        siteButton.classList.toggle("active");
      }
    });

    burgerMobile.addEventListener("click", () => {
      var siteButton = menu.querySelector(`li.${siteName}`), active = menu.querySelector("li.active");
      overlay.classList.toggle("active");
      document.body.classList.toggle("overlayed");
      menu.classList.toggle("menu-opened");
      document.body.classList.toggle("menu-opened");
      burgerMobile.classList.toggle("active");
      if(active && !active.classList.contains(siteName)) {
        active.classList.toggle("active");
      } else if(!active) {
        siteButton.classList.toggle("active");
      }
      if(document.body.classList.contains("minified")) document.body.classList.remove("minified");
    });

    overlay.addEventListener("click", () => {
      document.body.classList.remove("overlayed");
      overlay.classList.remove("active");
      burger.classList.remove("menu-opened");
      menu.classList.remove("menu-opened");
      if(headerSerie) {
        headerSerie.classList.remove("opened");
        if(headerEpisodes) headerEpisodes.style.top = "-1000px";
      }
    });

    menu.querySelectorAll("ul.sites > li").forEach(function(item) {
      item.addEventListener("click", () => {
        var active = menu.querySelector("li.active");
        if(active) active.classList.toggle("active");
        if(!active || (active && active.className !== item.className)) item.classList.toggle("active");
      });
    });

    if(headerSerie) {
      headerSerie.querySelectorAll(".summary, .summary-mobile").forEach(function(button) {
        button.addEventListener("click", () => {
          overlay.classList.toggle("active");
          document.body.classList.toggle("overlayed");
          headerSerie.classList.toggle("opened");
          var topValue = (headerSerie.classList.contains("opened"))?`${headerSerie.offsetHeight-1}px`:"-1000px";
          if(headerEpisodes) headerEpisodes.style.top = topValue;
        });
      });
    }

    if(audioSearch) {
      const categoryButtons = audioSearch.querySelectorAll(".audio-themes button.category");
      const selectedCategory = audioSearch.querySelector(".selected-category");
      categoryButtons.forEach(button => {
        button.addEventListener("click", function() {
          categoryButtons.forEach(btn => btn.classList.remove("active"));
          this.classList.add("active");
          selectedCategory.value = this.dataset.category;
        });
      });

      const audioMore = audioSearch.querySelector("button.more");
      const additional = audioSearch.querySelector(".additional");
      audioMore.addEventListener("click", () => {
        audioMore.classList.toggle("d-none");
        additional.classList.remove("d-none");
        additional.classList.add("d-flex");
      });
    }
  }

  disconnect() {}

  async loadPlaylist(event) {
    const playerController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="player"]'), 
      "player"
    );

    if(playerController) {
      const data = event.currentTarget.dataset;
      const contents = await axios.get("/board/api/v1/search-player", {
        params: {
          id: data.playerId,
          type: data.playerType
        }
      });

      if(contents.data) {
        var playlistIndex = data.playlistIndex ? data.playlistIndex : 0;

        playerController.unload();
        playerController.loadPlaylist(contents.data, playlistIndex);
      }
    }
  }
}
